import styled from "styled-components";
import { spacingHalf, spacing, headerFont, bpMobilePhablet, bpPhabletTablet, textPrimary, textSecondary, backgroundPrimary, borderRadius, spacingQuarter, backgroundSecondary } from "../../styles/_theme-variables";
import { articleFont, transitionColor } from "../../styles/_mixins";
import { Link } from "gatsby";

export const Info = styled.div`
    display: flex;

    @media screen and (min-width: ${bpMobilePhablet}) {
        flex-direction: row;
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex-direction: column;
        margin-bottom: ${spacingHalf};
    }
`;

export const HeroWrapper = styled.div`
    margin-bottom: ${spacing};
`;

export const GatsbyLink = styled(Link)`
    color: ${textSecondary};
    ${transitionColor};
`;

export const RelatedArticles = styled.div`
    display: flex;
    margin-bottom: ${spacing};

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex-direction: column;
    }
`;

export const RelatedTechnologies = styled.ul`
    margin: ${spacing};
`;

export const RelatedTechnology = styled.li`
    color: ${textPrimary};
    ${transitionColor};
    margin-bottom: ${spacingHalf};
    ${articleFont};
    margin-left: ${spacing};
`;

export const SectionHeader = styled.h2`
    font-family: ${headerFont};
    font-size: 2.4rem;
    color: ${textSecondary};
    ${transitionColor};
    font-weight: 400;
    margin-bottom: ${spacingHalf};
`;

export const RelatedContainer = styled.div`
    display: flex;
        
    @media screen and (min-width: ${bpMobilePhablet}) {
        flex-direction: row;
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex-direction: column;
    }
`;

export const RelatedContainerItem = styled.div`
    flex: 1;
`;

export const RelatedProjects = styled.ul`
    margin: ${spacing};
`;

export const CardWrapper = styled.div`
    @media screen and (min-width: ${bpPhabletTablet}) {
        flex-basis: 33.3%;
        padding: ${spacing};
    }

    @media screen and (min-width: ${bpMobilePhablet}) and (max-width: $bpPhabletTablet) {
        flex-basis: 50%;
        padding: ${spacing};
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex: 1;
        margin-bottom: ${spacing};
    }
`;

export const RelatedProject = styled.li`
    margin-bottom: ${spacingHalf};
    & a {
        text-decoration: none;
    }    
`;

export const RelatedProjectHeader = styled.h3`
    ${articleFont};

    a & {
        color: ${textPrimary};
        ${transitionColor};
        text-decoration: none;
        &:hover {
            color: ${textSecondary};
        }
    }
`;

export const Meta = styled.div`
    margin-bottom: ${spacing};
`;

export const PublishDate = styled.p`
    flex: 1;
    color: ${textPrimary};
    ${transitionColor};
    ${articleFont};    
    padding: ${spacing} 0;
`;

export const TagsWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
`;
