import React from "react";
import { graphql } from 'gatsby';

import SEO from "../../components/seo";

import PageWrapper from "../../components/_molecules/page-wrapper";
import HtmlContentWrapper from "../../components/_molecules/html-content-wrapper";
import HtmlContent from "../../components/_molecules/html-content";
import Card from "../../components/card";
import PostHero from "../../components/_organisms/post-hero";
import Tags from "../../components/_organisms/tags";

import {
    GatsbyLink as Link,
    RelatedArticles,
    RelatedTechnologies,
    RelatedTechnology,
    SectionHeader,
    RelatedContainerItem,
    RelatedContainer,
    RelatedProjects,
    CardWrapper,
    RelatedProject,
    RelatedProjectHeader,
    Meta,
    TagsWrapper
} from "./post-styles";
const mapRelatedProject = ({ title, slug }) => {
    return (
        <RelatedProject>
            <Link to={`portfolio/${slug}`}>
                <RelatedProjectHeader>{ title }</RelatedProjectHeader>
            </Link>
        </RelatedProject>
    );
};

// TODO: Introduce video support
// const mapVideo = ({ src, title, type }) => {
//     if (type === 'youtube') {
//         return (<YouTube youtubeId={src} />);
//     }

//     return null;
// }

export default ({ data }) => {
    const { postsJson: post } = data;
    return (
        <>
            <SEO
                title={post.title}
                description={post.metaDescription}
                image={(post.openGraphImage && post.openGraphImage[0].src.childImageSharp.fixed.src) || null}
                slug={`/blog/${post.slug}`}
            />
            <PostHero
                images={post.heroImages}
                title={post.title}
                description={post.metaDescription}
                meta={[
                    post.author.name,
                    new Date(post.publishDate).toDateString()
                ]}
                videos={post.heroVideos}
                thumbs={post.heroImages}
            />
            <TagsWrapper>
                { post.tags &&
                    <Tags tags={post.tags} />
                }
            </TagsWrapper>
            <PageWrapper>
                <HtmlContentWrapper>
                    { post.bodyContent && <HtmlContent html={post.bodyContent.html} /> }
                </HtmlContentWrapper>
                <Meta>
                    <RelatedContainer>
                        {   post.relatedTechnologies.length > 0 &&
                            <RelatedContainerItem>
                                <SectionHeader>Related Technologies</SectionHeader>
                                <RelatedTechnologies>
                                    { post.relatedTechnologies.map(t => (
                                        <RelatedTechnology>{ t }</RelatedTechnology>
                                    )) }
                                </RelatedTechnologies>
                            </RelatedContainerItem>
                        }
                        { post.relatedProjects.length > 0 &&
                            <RelatedContainerItem>
                                <SectionHeader>Related Projects</SectionHeader>
                                <RelatedProjects>
                                    { post.relatedProjects.map(mapRelatedProject) }
                                </RelatedProjects>
                            </RelatedContainerItem>
                        }
                    </RelatedContainer>
                    { post.relatedArticles.length > 0 &&
                        <div>
                            <SectionHeader>Related Articles</SectionHeader>
                            <RelatedArticles>
                                { post.relatedArticles.map(a =>
                                    (
                                        <CardWrapper>
                                            <Card title={a.title} image={a.heroImages[0]} publishDate={a.publishDate} slug={a.slug} />
                                        </CardWrapper>
                                    )
                                )}
                            </RelatedArticles>
                        </div>
                    }
                </Meta>
            </PageWrapper>
        </>        
    );
};

export const postQuery = graphql`
    query BlogPostByPath($slug: String!) {
        postsJson(slug: { eq: $slug }) {
            id
            title
            metaDescription
            heroImages {
                name
                src {
                    childImageSharp {
                        fixed(width: 700, height: 350, cropFocus: NORTH) {
                            ...GatsbyImageSharpFixed
                        }   
                        fluid(quality: 90, maxWidth: 1920, maxHeight: 350) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                        fluidThumb: fluid(quality: 90, maxWidth: 1920, maxHeight: 1080, cropFocus: NORTH) {
                            ...GatsbyImageSharpFluid_withWebp
                        }             
                    }   
                }
            }
            openGraphImage: heroImages {
                name
                src {
                    childImageSharp {
                        fixed(width: 1200, height: 630) {
                            src
                        }
                    }
                }
            }
            relatedProjects: _relatedProjects {
                title
                id
                slug
            }
            relatedArticles: _relatedArticles {
                title
                heroImages {
                    name
                    src {
                        childImageSharp {
                            fluid(maxWidth: 500, maxHeight: 360, cropFocus: NORTH) {
                                ...GatsbyImageSharpFluid_noBase64
                            }              
                        }   
                    }
                }
                slug
                publishDate
            }
            bodyContent: _bodyContent {
                id
                html
            }
            author: _author {
                name
            }
            publishDate
            relatedTechnologies
            slug
            tags
        }
    }
`;