import styled from "styled-components";
import { headerFont, borderRadius, textPrimary, textSecondary } from "../../../styles/_theme-variables";
import { articleFont, transitionColor } from "../../../styles/_mixins";
import { fluidFontSizes } from '../../../styles/_calculations';

export const Body = styled.div`

        color: ${textPrimary};
        ${transitionColor};

        ${articleFont};
        ${fluidFontSizes.bodySize}

        & h2, & h3, & h4 {
            font-family: ${headerFont};
            margin-bottom: 1em;
            font-weight: 400;
        }

        & p {
            ${articleFont};            
            margin-bottom: 2em;
        }

        & pre {
            margin-bottom: 1em;            
        }

        & hr {
            margin-bottom: 1em;
        }

        & img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            border-radius: ${borderRadius};
        }

        & ul, & ol {
            margin-bottom: 1em;
            padding: 0 1.5em;
            list-style: disc;
        }

        & li {
            margin-bottom: 1.5em;
        }

        & a {
            ${transitionColor};
            color: ${textSecondary};
        }

        & figcaption {
            text-align: center;
            padding: 0.8em;
            font-weight: bold;
        }
`;