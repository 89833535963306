import styled, { css } from 'styled-components';
import { transparentize } from "polished";
import BackgroundImage from 'gatsby-background-image';
import { leftAccentColor, basicWhite } from '../../../styles/_theme-variables';
import { transitionColor, headerFontMixin, articleFont } from '../../../styles/_mixins';
import { fluidFontSizes } from '../../../styles/_calculations';

const heroBackgroundColor = props => transparentize(0.2, props.theme.styles.color.primaryColorDarker);
const heroTextColor = leftAccentColor;
const metaColor = basicWhite;

const RootStyles = css`
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${heroBackgroundColor};
`;

export const Root = styled.div`
    ${RootStyles}
`;

export const GatsbyBackgroundImage = styled(BackgroundImage)`
    ${RootStyles}
    background-color: ${heroBackgroundColor};
`;

export const ContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${heroBackgroundColor};
    border-bottom: 1px solid ${heroTextColor};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 64em) {
        padding: 0.5em;
    }
`;

export const Header = styled.h1`
    text-align: center;
    color: ${heroTextColor};
    ${transitionColor};
    ${headerFontMixin};
    ${fluidFontSizes.header1Size}
    margin: 0;
    margin-bottom: 0.5em;
    line-height: 0.9;
`;

export const Description = styled.p`
    text-align: center;
    color: ${heroTextColor};
    ${transitionColor};
    ${articleFont};
    ${fluidFontSizes.bodySize}
    margin: 0;  
`;

export const Info = styled.ul`
    margin: 0;
    ${fluidFontSizes.bodySize}
    margin-bottom: 0.5em;
`;

export const Meta = styled.li`
    ${fluidFontSizes.bodySize}
    ${articleFont};
    color: ${metaColor};
    display: inline-block;
    padding: 0.5em;
`;