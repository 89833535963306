import React from "react";
import {
    GatsbyBackgroundImage,
    Root,
    ContentWrapper,
    Content,
    Header,
    Description,
    Info,
    Meta
} from "./post-hero-styles";
import { CenterWrapper } from '../../primitives/index';
import HtmlContentWrapper from "../../_molecules/html-content-wrapper";

export interface PostHeroProps {
    images: any[];
    thumbs: any[];
    videos: any[];
    title: string;
    description: string;
    meta: string[];
    tags: string[];
}

const PostHero = (props: PostHeroProps) => (
    <GatsbyBackgroundImage
        Tag="section"
        fluid={props.images[0].src.childImageSharp.fluid}
    >            
        <Root>
            <ContentWrapper>
                <CenterWrapper>
                    <Content>
                        <Header>{props.title}</Header>
                        <Info>
                            { props.meta.map(m => <Meta>{ m }</Meta>) }
                        </Info>
                        <HtmlContentWrapper>
                            <Description>
                                {props.description}
                            </Description>
                        </HtmlContentWrapper>
                    </Content>
                </CenterWrapper>
            </ContentWrapper>
        </Root>
    </GatsbyBackgroundImage>
);

export default PostHero;
