import React from "react";

import {
    Body
} from "./html-content-styles";

const HtmlContent = ({ html }) => (
    <Body
        className="c-htmlContent__body"
        dangerouslySetInnerHTML={{ __html: html }}
    />                    
)

export default HtmlContent;