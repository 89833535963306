import * as React from 'react';
import { ApplicationState } from '../../../providers/application-state.interface';
import { isExpandedActionTypes } from '../../../providers/reducers/is-expanded.reducer';
import { userModeActionTypes } from '../../../providers/reducers/user-mode.reducer';
import { Tags as Root, Tag as Item } from '../../primitives';
import withApplicationState from '../../_hocs/with-application-state';
import { connectSearchBox } from 'react-instantsearch-dom';
import { TagButton } from '../../primitives/index';

interface TagsProps {
    refine: any,
    tags: string[]
    state: ApplicationState;
    dispatch: any;
}

function Tags(props: TagsProps) {

    function search(tag: string) {
        props.dispatch({ type: isExpandedActionTypes.EXPAND });
        props.dispatch({ type: userModeActionTypes.SET_USER_MODE, payload: 'search' });
        props.dispatch(
            () => setTimeout(() => props.refine(tag), 300)
        );
        
        
        props.refine(tag);
    }

    return (
        <Root>
            { 
                props.tags.map(t => (
                    <Item>
                        <TagButton onClick={() => search(t)}>{ t }</TagButton>
                    </Item>
                ))
            }
        </Root>
    )
}

export default connectSearchBox(withApplicationState(Tags));